import yletv1Logo from './assets/yle-tv1.png'
import yletv2Logo from './assets/yle-tv2.png'
import yleteemafemLogo from './assets/yle-teemafem.png'
import tv5Logo from './assets/tv5.png'
import foxLogo from './assets/fox.png'
import subLogo from './assets/sub.png'
import livLogo from './assets/liv.png'
import kutonenLogo from './assets/kutonen.png'
import jimLogo from './assets/jim.png'
import nelonenLogo from './assets/nelonen.png'
import avaLogo from './assets/ava.png'
import friiLogo from './assets/frii.png'
import mtv3Logo from './assets/mtv3.png'
import alfatvLogo from './assets/alfatv.png'
import heroLogo from './assets/hero.png'
import estradiLogo from './assets/estradi.png'
import nationalgeographicLogo from './assets/natgeo.png'
import tlcLogo from './assets/tlc.png'
import viasatUrheiluLogo from './assets/viasat-urheilu.png'
import viasatUrheiluHDLogo from './assets/viasat-urheilu-hd.png'
import mtLogo from './assets/mt.png'
import inezLogo from './assets/inez.png'
import livechatLogo from './assets/livechat.png'
import oneHdMusicTelevisionLogo from './assets/1hd-music-television.png'
import animalPlanetLogo from './assets/animal-planet.png'
import cMoreFirstLogo from './assets/c-more-first.png'
import cMoreHitsLogo from './assets/c-more-hits.png'
import cMoreJunioriLogo from './assets/c-more-juniori.png'
import cMoreMaxLogo from './assets/c-more-max.png'
import cMoreSeriesLogo from './assets/c-more-series.png'
import cMoreSport1Logo from './assets/c-more-sport-1.png'
import cMoreSport2Logo from './assets/c-more-sport-2.png'
import discoveryLogo from './assets/discovery.png'
import disneyChannelLogo from './assets/disney-channel.png'
import disneyJuniorLogo from './assets/disney-junior.png'
import disneyXdLogo from './assets/disney-xd.png'
import eurosport1Logo from './assets/eurosport-1.png'
import eurosport2Logo from './assets/eurosport-2.png'
import investigationDiscoveryLogo from './assets/investigation-discovery.png'
import natGeoWildLogo from './assets/nat-geo-wild.png'
import nickJrLogo from './assets/nick-jr.png'
import viasatFilmPremiereLogo from './assets/viasat-film-premiere.png'
import viasatGolfLogo from './assets/viasat-golf.png'
import viasatHockeyLogo from './assets/viasat-hockey.png'
import viasatJaakiekkoLogo from './assets/viasat-jaakiekko.png'
import viasatJalkapalloLogo from './assets/viasat-jalkapallo.png'
import viasatSportPremiumLogo from './assets/viasat-sport-premium.png'
import viasatSportLogo from './assets/viasat-sport.png'
import viasatXtra1Logo from './assets/viasat-xtra-1.png'
import viasatXtra2Logo from './assets/viasat-xtra-2.png'
import viasatXtra3Logo from './assets/viasat-xtra-3.png'
import viasatXtra4Logo from './assets/viasat-xtra-4.png'
import viasatXtra5Logo from './assets/viasat-xtra-5.png'
import onewayTvLogo from './assets/onewaytv.png'
import suomiAreenaTvLogo from './assets/suomi-areena-tv.png'
import pulseaLogo from './assets/pulsea.png'
import eveoLogo from './assets/eveo.png'

export const channelThemes = {
  colors: {
    yletv1: '#6FA0FF',
    yletv2: '#B134F5',
    yleteemafem: '#BCD61D',
    tv5: '#B5B5B5',
    fox: '#D4A506',
    sub: '#EBD40F',
    liv: '#00E000',
    kutonen: '#87E3D8',
    jim: '#F7F263',
    nelonen: '#5570F5',
    ava: '#853D60',
    frii: '#C2485A',
    mtv3: '#E43112',
    alfatv: '#A42F12',
    hero: '#DF9387',
    popup: '#fff',
    estradi: '#CA005D',
    nationalgeographic: '#fbdc3a',
    tlc: '#ed697b',
    'tv-etusivu': '#fff',
    viasaturheilu: '#4848cd',
    viasaturheiluhd: '#74bf00',
    mt: '#9E9E9E',
    inez: '#ffe900',
    livechatfi: '#d6d6d6',
    '1hdmusictelevision': '#d6d6d6',
    animalplanet: '#d6d6d6',
    cmorefirst: '#d6d6d6',
    cmorehits: '#d6d6d6',
    cmorejuniori: '#d6d6d6',
    cmoremax: '#d6d6d6',
    cmoreseries: '#d6d6d6',
    cmoresport1: '#d6d6d6',
    cmoresport2: '#d6d6d6',
    discovery: '#d6d6d6',
    disneychannel: '#d6d6d6',
    disneyjunior: '#d6d6d6',
    disneyxd: '#d6d6d6',
    eurosport1: '#d6d6d6',
    eurosport2: '#d6d6d6',
    investigationdiscovery: '#d6d6d6',
    natgeowild: '#d6d6d6',
    nickjr: '#d6d6d6',
    viasatfilmpremiere: '#d6d6d6',
    viasatgolf: '#d6d6d6',
    viasathockey: '#d6d6d6',
    viasatjääkiekko: '#d6d6d6',
    viasatjalkapallo: '#d6d6d6',
    viasatsportpremium: '#d6d6d6',
    viasatsport: '#d6d6d6',
    viasatxtra1: '#d6d6d6',
    viasatxtra2: '#d6d6d6',
    viasatxtra3: '#d6d6d6',
    viasatxtra4: '#d6d6d6',
    viasatxtra5: '#d6d6d6',
    onewaytv: '#d6d6d6',
    suomiareenatv: '#d6d6d6',
    pulsea: '#d6d6d6',
    eveo: '#36e7df'
  },
  logos: {
    yletv1: yletv1Logo,
    yletv2: yletv2Logo,
    yleteemafem: yleteemafemLogo,
    tv5: tv5Logo,
    fox: foxLogo,
    sub: subLogo,
    liv: livLogo,
    kutonen: kutonenLogo,
    jim: jimLogo,
    nelonen: nelonenLogo,
    ava: avaLogo,
    frii: friiLogo,
    mtv3: mtv3Logo,
    alfatv: alfatvLogo,
    hero: heroLogo,
    estradi: estradiLogo,
    nationalgeographic: nationalgeographicLogo,
    tlc: tlcLogo,
    viasaturheilu: viasatUrheiluLogo,
    viasaturheiluhd: viasatUrheiluHDLogo,
    mt: mtLogo,
    inez: inezLogo,
    livechatfi: livechatLogo,
    '1hdmusictelevision': oneHdMusicTelevisionLogo,
    animalplanet: animalPlanetLogo,
    cmorefirst: cMoreFirstLogo,
    cmorehits: cMoreHitsLogo,
    cmorejuniori: cMoreJunioriLogo,
    cmoremax: cMoreMaxLogo,
    cmoreseries: cMoreSeriesLogo,
    cmoresport1: cMoreSport1Logo,
    cmoresport2: cMoreSport2Logo,
    discovery: discoveryLogo,
    disneychannel: disneyChannelLogo,
    disneyjunior: disneyJuniorLogo,
    disneyxd: disneyXdLogo,
    eurosport1: eurosport1Logo,
    eurosport2: eurosport2Logo,
    investigationdiscovery: investigationDiscoveryLogo,
    natgeowild: natGeoWildLogo,
    nickjr: nickJrLogo,
    viasatfilmpremiere: viasatFilmPremiereLogo,
    viasatgolf: viasatGolfLogo,
    viasathockey: viasatHockeyLogo,
    viasatjääkiekko: viasatJaakiekkoLogo,
    viasatjalkapallo: viasatJalkapalloLogo,
    viasatsportpremium: viasatSportPremiumLogo,
    viasatsport: viasatSportLogo,
    viasatxtra1: viasatXtra1Logo,
    viasatxtra2: viasatXtra2Logo,
    viasatxtra3: viasatXtra3Logo,
    viasatxtra4: viasatXtra4Logo,
    viasatxtra5: viasatXtra5Logo,
    onewaytv: onewayTvLogo,
    suomiareenatv: suomiAreenaTvLogo,
    pulsea: pulseaLogo,
    eveo: eveoLogo
  }
};
